@import url('https://fonts.googleapis.com/css2?family=Anta&family=Oswald:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');



.google-sign-in-button {
    cursor: pointer;
    transition: background-color .3s, box-shadow .3s;
        
    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, 0.25);
    
    color: #554141;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);

}

.google-sign-in-button:hover {
    
}

.google-sign-in-button:active {
    background-color: #eeeeee;
}

.google-sign-in-button:active {
    outline: none;
        box-shadow: 
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
}

.google-sign-in-button:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
}


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;


}

body {
    line-height: 1.5;
}



.showcase {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: black;
    
}

.video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: var(--primary-color) url("/public/stillvideo.jpg") no-repeat center center/cover;
    background-size: cover;


}

.video-container video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video fills the container without stretching */
}

.content {
    z-index: 10;
    padding: 10px;
   
}

.content h1 {
    cursor: default;
    color: white;
    font-size: 7rem;
    margin: 10px;
    font-weight: 00;
    text-shadow: black 5px 0 10px;
    font-family: "Oswald", sans-serif;

    
}


.content h3 {
    cursor: default;
    color: white;
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    text-shadow: black 5px 0 10px;
    font-family: "segue-ui", sans-serif;
    justify-content: space-between;
    
}

.about {
    cursor: default;
    display: flex;
    background-color: black;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    text-align: left; /* Center text inside items */
    height: 650px; /* Full height */
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url("/public/image.svg");
    background-size: cover;
  }
  
  .about h3 {
    padding: 20px;
    cursor: default;
    font-family: 'Mulish', sans-serif;
    color: white;
    font-size: 18px;
    font-weight: 300;
    max-width: 800px;
    text-align: left;

  }
  
  .about a {
    font-family: 'Mulish', sans-serif;
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 800px;
  }

@media only screen and (max-width: 900px) {
    .content h1{
      font-size: 70px;
    }
    .about h3 {
        font-size: 15px;
    }
    .about {
    }

  }
