@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');:root{
background-color: #000000;
    padding: 0; /* Reset padding */
    margin: 0; /* Reset margin */
    cursor: default;
}


@font-face {
    font-family: "UniversLT";
    src: url("/public/Univers 93 Extra Black Extended Regular.otf") format("opentype");
    font-weight: 900; /* Optional: Specify font weight */
    font-style: normal; /* Optional: Specify font style */
}

.hero-bg {
    background: linear-gradient(
            rgba(0, 0, 0, 0.8),
            rgba(0, 0, 0, 0.4)
    ),
    url("/public/Starry.jpg");
    background-size: cover; /* Ensure the background image fully covers the container */
    background-position: center;
    margin-left: 0px;

}

.hero-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 800px;
    width: 100%;
    max-width: 1200px; /* Limit the width of the container */
    margin: 0 auto; /* Center the container horizontally */

}

.hero-text {
    font-family: UniversLT;
    color: white;
    text-align: center;
    display: flex; /* Enables flexbox for vertical centering */
    flex-direction: column; /* Aligns text vertically */
    padding: 10px;
}

.hero-text img {
    height: 22rem; /* Sets a fixed height */
    margin-bottom: 0; /* Resets any unnecessary margin */
    max-width: 100%; /* Makes it responsive */
    height: auto;
}

.hero-text h4 {
    font-size: 20px;
    font-family: Barlow;
    text-align: left;
}

.hero-text h3 {
    text-align: start;
    font-family: Barlow;
    font-size: 45px;
}

.hero-video {
    display: flex;
    max-width: 100%; /* Add units */
    max-height: 100%; /* Add units */
}

.hero-video iframe {
    border: 2px solid white;

}
/* Show the video on large screens */
@media (min-width: 1024px) {
}



@media (max-width: 1024px) {
    .hero-video img {
        display: none; /* Show the astronaut image on larger screens */
    }


}
@media (max-width: 750px) {
    .hero-container { /* Display items side by side on larger screens */
        justify-content: center;
        flex-direction: column; 


    }
}


.transition-gradient {
    height: 4rem;
    background: linear-gradient(
            to bottom, /* Add direction (e.g., "to right", "to bottom") */
            rgb(200, 35, 55) 0%,
            rgb(200, 35, 55) 25%,
            rgb(224, 98, 54) 25%,
            rgb(224, 98, 54) 50%,
            rgb(215, 171, 97) 50%,
            rgb(215, 171, 97) 75%,
            rgb(47, 76, 121) 75%,
            rgb(47, 76, 121) 100%
    );
}

.all-videos-container {
 
  background: #ffffff;
    background-image: url("/public/pattern.png");
    background-color: rgba(255, 255, 255, 0.4); /* White with 50% opacity */
    background-blend-mode: overlay; /* Blend the color with the image */
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 850px;
    width: 100%;
    

    
}

.video-title {
    font-family: Barlow;
    display: flex;
    color: black;
    font-size: 24px;
    justify-content: center; /* Add this line to center horizontally */
    top: 0; /* Add this line to position it at the top */
    width: 100%; /* Add this line to make it stretch across the container */
    padding-top: 20px;
}


.video-gallery {
  background-color: #ffffff;
    justify-content: center;
    width: 940px;
    max-width: 1200px;
    height: 570px;
    flex-wrap: wrap;
    margin: auto;
    position: relative; /* Add this line */
    border: 4px solid rgb(0, 0, 0);
    display: flex; 


}

.video-ind {
    font-size: 14px;
    font-weight: 700;
    margin-left: 20px;
    padding-top: 20px;
    width: 100%;
    
}

.video-ind iframe {
  height: 396.5px;
  width: 705px;
  
}

.video-ind h2{
    font-family: Barlow;
    white-space: nowrap; /* Prevent text from wrapping */
  text-overflow: ellipsis;
    color: #000000;
    font-weight: 800;
  max-width: 100%;
  font-size: 40px;
  margin-top: 15px;
  margin-øef: 15px;

    white-space: nowrap; /* Add this line */
    text-overflow: ellipsis;
}

.video-ind h3 {
    font-family: Barlow;
  display: inline-block; /* or display: block; */
    max-width: 500px; /* Your desired max-width value */
    color: #000000;
    font-weight: 400;
    font-size: 20px;
    white-space: nowrap; /* Add this line */

    
    text-overflow: wrap;
}

.search{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: -100px;

}

.search input{
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.87);
  color: black;
  border: 2px solid rgb(0, 0, 0);
  width: 350px;
  height: 40px;
  padding-left: 40px; /* Adjust as needed based on icon size */
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="24"><path fill="%2300000" d="M21.71 20.29l-5.07-5.07A7.93 7.93 0 0 0 18 10c0-4.41-3.59-8-8-8s-8 3.59-8 8 3.59 8 8 8c1.57 0 3-.46 4.23-1.24l5.07 5.07c.39.39 1.02.39 1.41 0s.39-1.02 0-1.41zM10 16c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6z"></path></svg>'); /* Or use an icon font */
  background-repeat: no-repeat;
  background-position: left;
  outline: none; /* Remove the default focus border */
  font-family: Barlow;
    font-size: 19px;
}



.vertical-menu {
    position: absolute;
    font-family: inter;
    background-color: #171717;;
    color:  #000000;
    right: 0; /* Stick to the right side */
    top: 0; /* Align with the top of the container */    
    width: 200px; /* Set a width if you like */
    height: 100%;
    overflow-y: scroll;
    border-left: 3px solid rgb(0, 0, 0);
    scrollbar-width: none;
    
  }

  .vertical-menu::-webkit-scrollbar{
    display: none;
  }

  .vertical-menu a {
    background-color:   #121212; /* Grey background color */
    font-family: Barlow;
    color: rgb(219, 219, 219); /* Black text color */
    display: block; /* Make the links appear below each other */
    padding: 19px; /* Add some padding */
    text-decoration: none; /* Remove underline from links */
    font-weight: 500;
      font-size: 19px;
    text-align: start;
    border-bottom: 0.1px solid rgb(53, 53, 53);
     }
  
  .vertical-menu a:hover {
    cursor: pointer;
    background-color: #242424; /* Dark grey background on mouse-over */
  }
  
  .vertical-menu a.active {
    background-color: #2a2a2a;
    color: white;
    font-weight: 600;
  }

  .accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
  }
  
  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .active, .accordion:hover {
    background-color: #ccc;
  }
  
  /* Style the accordion panel. Note: hidden by default */
  .panel {
    padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  }

  .vertical-menu .mobile-menu {
   display: none;
  }

  
  @media only screen and (max-width: 930px) {

    .vertical-menu .desktop-menu {
      display: none; /* Hide desktop menu */
    }
  
    .vertical-menu .mobile-menu {
      display: flex;
      justify-content: center ;
      align-items: center;
  
    }

    .select {
      align-items: center;
      margin-top: 10px;
      justify-content: center;
      font-family: Barlow;
      height: 50px;
      background-color: black;
      color: white;
      font-weight: 500;
      font-size: 18px;
      padding: 10px;
    }

    .all-videos-container {
      height: 700px;
    }

    
    .video-gallery {
      margin-top: 20px;
        display: flex;
        flex-direction:column; /* Stack elements vertically on mobile */
        height: auto; /* Auto height for responsive layout */
        width: 100%;
        max-width: 100%;
        justify-content: center;
        align-items: center;
    }

    .vertical-menu {
        position: relative; /* Remove absolute positioning */
        width: 100%; /* Take full width on mobile */
        max-width: 100%; /* Reset max-width */
        max-height: 200px; /* Auto height for responsive layout */
        margin-top: 20px; /* Add space between video and menu */
        border-top: 3px solid rgb(0, 0, 0); /* Add border on top */
        height: 80px;
    }

    
    .video-ind {
      display: flex;
      flex-direction: column; 
      justify-content: center;
      align-items: center;
      margin-left: 0;
      width: 100%;

    }

    .search{
      display: none;
      justify-content: center;
      align-items: center;
      width: 75%;
      max-width: 100%;
      margin-top: 10px;
      min-width: 200px;
      margin-bottom: -210px;
    }

    .hero-container{
      height: 600px;
        padding: 20px;
    }
    
    


    .video-ind iframe {
      max-width: 300px;
      height: 180px;
      z-index: 0;
      
    }

    .hero-video {
      padding: 10px;
      max-width: 100%;
      max-height: 100%;

    }

    .hero-video iframe {
      display: flex;
      max-width: 340px;
      max-height: 191px;
   
      
    }

    .about {
      padding: 10px;
    }

    .video-ind h2 {
      display: flex;
      overflow-wrap: normal;
      max-width: 300px;
      font-size: 15px;
      height: 25px;

    }
    .video-ind h3 {
      max-width: 300px;
      height: 35px;
      font-size: 15px;
      flex-wrap: wrap;
      text-wrap: wrap;

    }

    .hero-bg {
      display: flex;
      max-height: 500px;
      justify-content: center;
      align-items: center;
    }

    .hero-text h3 {
      font-size: 30px;
      font-weight: 900;
    }

    .hero-text h4 {
      font-size: 14px;
      font-weight: 500;

    }


    .all-videos-container{
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 1000px;
      margin-bottom: -150px;
          }

  
}


@media only screen and (max-width: 281px) {
  .video-gallery {

      display: flex;
      flex-direction:column; /* Stack elements vertically on mobile */
      height: auto; /* Auto height for responsive layout */
      width: 100%;
      max-width: 400px;
      justify-content: center;
      align-items: center;

  }

  .vertical-menu {
      display: flex;
      flex-direction: column;
      position: absolute; /* Remove absolute positioning */
      width: 100%; /* Take full width on mobile */
      max-width: 100%; /* Reset max-width */
      max-height: 175px; /* Auto height for responsive layout */
      margin-top: 20px; /* Add space between video and menu */
      border-top: 3px solid rgb(0, 0, 0); /* Add border on top */
      border-left: none; /* Add border on top */
  }

  .video-ind {
    margin-left: 0;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
  }


  .video-ind iframe {
    border-radius: 12px;
    max-width: 100%;
    max-height: 188px;
    
  }

  .hero-video {
    padding: 10px;
    max-width: 100%;
    max-height: 100%;

  }

  .hero-video iframe {
    display: flex;
    max-width: 100%;
    max-height: 100%;
 
    
  }

  .about {
    padding: 10px;

  }

  .video-ind h2 {
    display: flex;
    overflow-wrap: normal;
    max-width: 100%;
    font-size: 15px;
    max-height: 100%;

  }
  .video-ind h3 {
    max-width: 100%;
    font-size: 15px;
    text-overflow: wrap;
  }

  .hero-bg {
    display: flex;
    max-height: 600px;
    justify-content: center;
    align-items: center;
  }


  .hero-text h3 {
    font-size: 20px;
    font-weight: 900;
  }

  .hero-text h4 {
    font-size: 15px;
    font-weight: 500;

  }


  .all-videos-container{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 600px;
        }


}