@import url('https://fonts.googleapis.com/css2?family=Anta&family=Oswald:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');


nav {
  position: fixed;
  background-image: url("/public/pattern.png");
  background-color: rgba(255, 255, 255, 0.9); /* White with 50% opacity */
  background-blend-mode: overlay; /* Blend the color with the image */
  background-size: cover;  width: 100%; /* Ensures the navbar spans the full width */
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  height: 70px;
  font-family: "Univers LT Std 93 XBlk Ext", sans-serif;
  color: black;
  z-index: 1
}

nav ul a{
  color: black;
}

nav a:hover{
  cursor: pointer;
  color: black;

}

ul {
  flex: 1;
  width: 50px;
  display: inline-flex;
  flex-direction: row;
  list-style-type: none;
  margin-left: 30px;
  padding-bottom: 0;
  margin-right: 70px;
  justify-content: flex-start;
  color: black;

}


.button {
  color: black;
  font-family: UniversLT;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin: 30px;
}

.button:hover  {
  cursor: pointer;
  border-bottom: 2px solid rgb(0, 0, 0);
}

.Logo {
  font-family: UniversLT;
  color: black;
  font-size: 22px;
  font-weight: 500;
  margin-left: 2rem;
  text-decoration: none;
  cursor: pointer;
}

.hamburger-button {
  display: none;
}

.sign-out {
  display: inline-block;
  flex-direction: column;
  margin-right: 50px;
  color: black;
  width: 450px;

}


.sign-out h4 {
  font-family: Barlow;
  font-size: 18px;
}

.sign-out-btn {
  position: relative;
  border: solid 2.5px black;
  padding: 5px 40px;
  color: black;
  font-family: UniversLT;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  margin: 30px;
}

.sign-out-btn::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: #b53a3a;
  z-index: -1;
  transition: height 0.3s ease;
}

.sign-out-btn:hover::before {
  height: 100%;
}

.sign-out-btn:hover {
  color: white;
}

.sign-out img {
  display: inline-block;
  border-radius: 10px;
  height: 35px;
  width: 35px;
  vertical-align: middle;
}

.sign-out h4 {
  display: inline-block;
  vertical-align: middle;
  margin-left: 13px;
  color: black;
}

@media only screen and (max-width: 1100px) {

  .sign-out{
    display: none;
  }

  
  .hamburger-button {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-size: 24px;
    cursor: pointer;
    background: transparent;
    border: none;
    color: #000000;
  }

  
  

  nav {
    position: fixed;
  }

  

  li {
    display: none;
  }

  .button{
    display:none;
  }

  .Logo {
    font-size: 17px;
    margin-left: 1.4rem;
    width: 100%;
  }

}


.sidenav {
  height: 100%;
  width: 0;
  position: fixed; 
  z-index: 3; 
  top: 0; 
  right: 0;
  background-color: #0f0f0f; 
  overflow-x: hidden; 
  white-space: nowrap;
  padding-top: 60px; 
  transition: 0.3s; 

}

.sidenav.isOpen {
  width: 2px; /* When open, set width */
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  font-weight: 800;
  text-decoration: none;
  font-size: 25px;
  color: #ffffff;
  font-family: UniversLT;
  overflow-x: hidden; 
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.sidenav img {
  display: inline-block;
  font-family: inter;
  vertical-align: middle;
  margin-left: 20px;
  margin-right: 8px;
  height: 40px;
  border-radius: 50px;
  font-size: 40px;
  color: #ffffff;
  overflow-x: hidden; 
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 40px;

}

.sidenav h4 {
  font-family: inter;
  display: inline-block;
  vertical-align: middle;
  font-weight: 500;
  text-decoration: none;
  font-size: 15px;
  color: #ffffff;
  white-space: nowrap;
  margin-bottom: 40px;
}

.sidenav a:hover {
  color: #b8b8b8;
}

.sidenav .closebtn {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}




@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

